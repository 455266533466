<script>

import { audit } from "@/api/my";
export default {
    name:'Withdraw_Rule',
    components: {
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
    },
    data() {
        return {
            time: "",
            outExpense: 10,
            expense: 10,
            orderCount: "",
            repeatTime: "",
            userRepeatCount: "",
            buttonbool: "",
            repeatCount: "",

            header: {
                mainTitle: getWord('withdraw'),
            },
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        //稽核
        myaudit() {
            audit().then(result => {
                if (result.data.code == "SUCCESS") {
                    this.buttonbool = true;
                    this.time = result.data.result.time;
                    this.outExpense = result.data.result.outExpense;
                    this.expense = result.data.result.expense;
                    this.orderCount = result.data.result.orderCount;
                    this.repeatTime = result.data.result.repeatTime;
                    this.userRepeatCount = result.data.result.userRepeatCount;
                    this.repeatCount = result.data.result.repeatCount;
                } else {
                    app.Mint.Toast(result.data.msg);
                    this.buttonbool = false;
                    this.time = res.data.time;
                }
            });
        },
        getmonny() {
            this.$router.push({
                path: "/my/cash_withdrawal/payment_information?expense=" +
                    this.expense +
                    "&outExpense=" +
                    this.outExpense
            });
        }
    },
    mounted() {
        this.myaudit();
    }
};
</script>
<template>
    <section id="withdraw-rule" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
        </template>
        <div class="myposition"></div>
        <div class="main">
            <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
                <mt-header :title="getWord('withdraw')" style="height:0.88rem;font-size: 0.35rem;">
                    <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
                </mt-header>
            </template>
            
            <span class="main_span1">{{ getWord('withdraw_rules5') }}!</span>
            <div class="main_content">
                <div class="main_content_span">{{ getWord('withdraw_rules6') }}：{{orderCount}}</div>
                <div class="main_content_span">{{ getWord('withdraw_rules7') }}！</div>
                <div class="main_content_span" v-if="expense">{{ getWord('withdraw_rules8') }}
                    <span style="color:red">{{expense}}%</span>
                </div>
                <div class="main_content_span" v-if=" ! expense">{{ getWord('withdraw_rules9') }}！</div>
                <div class="main_content_span" v-if="! outExpense">{{ getWord('withdraw_rules10',{
                    '$1':repeatTime,
                    '$2':repeatCount,
                }) }}</div>
                <div class="main_content_span" v-if="outExpense">
                    {{ getWord('withdraw_rules11',{
                        '$1':repeatTime,
                        '$2':userRepeatCount,
                    }) }}
                    <span style="color:red">{{outExpense}}</span>{{ currencySymbol() }}！
                </div>
                <div class="main_content_span" v-if="buttonbool">{{ getWord('withdraw_rules12') }} {{new Date(time*1000).toLocaleDateString().replace(/\//g, "-") + " " + new Date(time*1000).toTimeString().substr(0, 8)}}</div>
                <div class="main_content_span" v-if="!buttonbool">{{ getWord('withdraw_rules12') }} {{time}}</div>
            </div>
        </div>
        <template v-if="_TEMPLATE==='template-3'">
            <span class="beizhu">{{ getWord('withdraw_rules13',{
                '$1':userRepeatCount
            }) }}</span>
        </template>
        <template v-else>
            <span class="beizhu">{{ getWord('withdraw_rules13',{
                '$1':userRepeatCount
            }) }}</span>
        </template>
        <mt-button class="main_button" type="primary" @click="getmonny()" v-if="buttonbool">{{ getWord('continue_withdraw') }}</mt-button>
        <mt-button class="main_button" type="primary" @click="getmonny()" disabled v-if="!buttonbool">{{ getWord('continue_withdraw') }}</mt-button>
    </section>
</template>
<style scoped lang='scss' style="text/css">
#withdraw-rule {

    &.template-3 {

        .main {

            .main_span1 {
                float: none;
                margin: 0;
                width: auto;
                color: #B0B0B0;
                padding: .25rem;
                display: block;
            }

            .main_content {
                float: none;
                width: auto;
                height: auto;
                margin-top: 0;
                padding: .25rem;

                .main_content_span {
                    float: none;
                    height: auto;
                    margin: auto;
                    color: #5F646E;
                    margin-bottom: .33rem;
                    font-size: .28rem;
                }
            }            
        }

        .beizhu {
            float: none;
            width: auto;
            height: auto;
            font-size: .24rem;
            color: #b0b0b0;
            margin: .25rem;
            display: block;
        }

        .main_button {
            float: none;
            width: auto;
            height: auto;
            margin: 0 auto;
            display: block;
            width: 84%;
            font-size: .34rem;
            padding: .2rem 0;
            background-color: #EC2829;
        }
    }

    .myposition {
        background-color: rgba(239, 239, 239, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .main_span1 {
        float: left;
        width: 4rem;
        font-size: 0.3rem;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        margin: 0.4rem 0 0 0.2rem;
    }

    .main_content {
        float: left;
        width: 100%;
        height: 6.48rem;
        margin-top: 0.2rem;
        background: rgba(255, 255, 255, 1);
    }

    .main_content_span {
        float: left;
        font-size: 0.3rem;
        margin: 0.2rem 0 0.2rem 0.2rem;
        color: rgba(51, 51, 51, 1);
        word-break: break-all;
    }

    .main_button {
        float: left;
        width: 6.71rem;
        height: 0.98rem;
        margin: 0.6rem 0 0 0.4rem;
    }

    .beizhu {
        float: left;
        width: 80%;
        height: 0.2rem;
        font-size: 0.2rem;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        margin: 0.3rem 0 0 0.2rem;
    }
}

</style>